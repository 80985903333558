<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
    <b-modal id="modal-danger" ok-only ok-variant="danger" ok-title="Delete" modal-class="modal-danger" centered
      title="Are you sure to delete this Team?" v-model="openDeleteModal" @ok="deleteTeams">
    </b-modal>

    <b-sidebar lazy id="sidebar-backdrop" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <addTeamsForm :closeSidebar="closeSidebar" :reloadParent="load" />
    </b-sidebar>
    <b-sidebar lazy id="sidebar-backdrop-edit" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header
      shadow>
      <editTeamsForm :closeSidebarEdit="closeSidebarEdit" :reloadParent="load" :teamid="teamid" />
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Teams</h3>
            <p class="mb-0">Manage your Teams.</p>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-between">
            <b-button size="sm" @click="handleNewVendorClick" variant="success"><feather-icon class="mr-50"
                icon="PlusIcon" />Add Teams</b-button>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon v-if="filters.search == null || filters.search == ''" icon="SearchIcon"
                class="text-muted" />
              <feather-icon @click="() => {
      filters.search = null;
    }
    " v-else icon="XIcon" class="text-danger cursor-pointer" />
            </b-input-group-prepend>
            <b-form-input v-model="filters.search" placeholder="Search..." debounce="500" size="md" />
          </b-input-group>
        </div>
      </div>

      <table role="table" class="table table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Name</th>
            <th role="columnheader" scope="col">Total Users</th>
            <th role="columnheader" scope="col">Created At</th>
            <th role="columnheader" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-if="teamsList.length != 0">
            <tr v-for="(team, index) in teamsList" :key="team._id" role="row" class="cursor-pointer"
              @click="handleTeamClick(team._id)">
              <td aria-colindex="2" role="cell">
                {{ (filters.page - 1) * filters.limit + index + 1 }}
              </td>
              <td aria-colindex="2" role="cell" class="text-start" style="max-width: 12vw">
                <p class="font-weight-bold mb-0">
                  {{ team.name }}
                </p>
              </td>

              <td aria-colindex="2" role="cell">
                <b-avatar variant="light-primary">
                  {{ team.users.length }}
                </b-avatar>
              </td>
              <td aria-colindex="2" role="cell">
                <p class="font-weight-bold mb-0">
                  {{ team.created_at | moment }}
                </p>
              </td>
              <td>
                <b-button @click.stop="handleEditClick(team._id)" b-v-tooltip.hover.top.v-secondary
                  title="Edit Assessment" variant="primary" size="sm" class="mr-50"><feather-icon icon="EditIcon"
                    class="mr-50" /><span class="align-middle">Edit</span></b-button>
                <b-button @click.stop="handleDeleteClick(team._id)" b-v-tooltip.hover.top.v-secondary
                  title="Edit Assessment" variant="danger" size="sm"><feather-icon icon="TrashIcon"
                    class="mr-50" /><span class="align-middle">Delete</span></b-button>
              </td>
            </tr>
          </template>
          <template v-else>
            <empty-table-section title="Teams Not Found">
              <template #content>
                <p class="font-weight-bold text-center">
                  It seems like there are no Teams at the moment.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>

      <div class="card-body">
        <div class="d-flex align-items-center justify-content-center w-100">
          <b-pagination v-model="filters.page" :per-page="pagination.perPage"
            :total-rows="pagination.lastPage * pagination.perPage" aria-controls="my-table"></b-pagination>
          <!-- {{ pagination }} -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BPagination,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import TeamsMixins from "../../mixins/TeamsMixins";
import moment from "moment";
import addTeamsForm from './components/addTeamsForm.vue';
import editTeamsForm from './components/editTeamsForm.vue';
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: {
    BButton,
    BSidebar,
    BOverlay,
    BPagination,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    addTeamsForm,
    editTeamsForm,
    BModal,
    EmptyTableSection
  },
  mixins: [ResponseMixins, TeamsMixins],
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      showOverlay: false,
      teamsList: null,
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
      },
      filters: {
        search: null,
        page: 1,
        limit: 10,
      },
      teamid: '',
      openDeleteModal: false
    };
  },
  watch: {
    filters: {
      handler: function (newValue) {
        this.getTeamsAndSetData(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isUserVendor()) {
      console.log("VendorId", this.$store.state.app.user.vendor_id);
      this.$router.push(
        `/thirdpartyrisks/${this.$store.state.app.user.vendor_id}/details`
      );
    } else {
      this.load();
    }
  },

  methods: {
    load() {
      this.getTeamsAndSetData(this.filters);
    },

    isUserVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleTeamClick(id) {
      this.$router.push(`/teamsdetails/${id}`);
    },

    getTeamsAndSetData(params) {
      this.showOverlay = true;
      this.getTeams(params)
        .then((res) => {
          this.pagination.lastPage = res.data.last_page;
          this.pagination.totalRows = res.data.total;
          this.filters.page = res.data.current_page;
          this.filters.limit = res.data.per_page;
          this.teamsList = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewVendorClick() {
      this.openSidebar();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
    closeSidebarEdit: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop-edit");
    },
    openSidebarEdit: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop-edit");
      });
    },
    handleEditClick(teamid) {
      this.teamid = teamid
      this.openSidebarEdit()
    },
    handleDeleteClick(teamid) {
      this.teamid = teamid;
      this.openDeleteModal = true;
    },
    deleteTeams() {
      this.deleteTeam(this.teamid).then((res) => {
        this.handleResponse(res);
        this.getTeamsAndSetData(this.filters);
      })
        .catch((err) => {
          this.handleError(err)
        })
    }
  },
};
</script>


<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>


<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>