<template>
    <div>
        <div class="card">
            <div class="card-header border-bottom d-flex">
                <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
                    <h2 class="font-weight-bolder">
                        <span class="align-middle">Edit Teams</span>
                    </h2>
                    <p class="card-subtitle text-muted pt-75">
                        Fill the form and click submit to edit team.
                    </p>
                </div>

                <div class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" @click="closeSidebarEdit()" variant="outline-secondary"><feather-icon size="24"
                            icon="XIcon" /></b-button>
                </div>
            </div>
        </div>
        <div class="p-2">
            <validation-observer ref="add_vendor_form" #default="{ invalid }">
                <b-form class="" @submit.prevent="handleSubmitClick()">
                    <b-form-group label="Name">
                        <template v-slot:label>
                            Name <span class="text-danger">*</span>
                        </template>
                        <validation-provider #default="{ errors }" vid="Name" name="Name" :rules="{
                        required: true,
                        min: 3,
                    }">
                            <b-form-input v-model="formData.name" id="name"
                                :state="errors.length > 0 ? false : null"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Description">
                        <template v-slot:label>
                            Description <span class="text-danger">*</span>
                        </template>
                        <validation-provider #default="{ errors }" vid="Description" name="Description" :rules="{
                        required: true,
                    }">
                            <b-form-textarea id="description" placeholder="Description" rows="3"
                                v-model="formData.description" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Add Users">
                        <v-select v-model="userselected" label="text" :options="users_options"  multiple
                            state="errors.length > 0 ? false:null" style="width: 100%;"
                            :reduce="loc => loc.value"/>
                    </b-form-group>
                    <div class="w-100 d-flex justify-content-center align-items-center">
                        <b-button type="submit" variant="success" class="mt-1 w-25" :disabled="invalid">
                            <feather-icon icon="CheckIcon" class="mr-50" />
                            <span class="align-middle">Update</span>
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </div>

    </div>
</template>
<script>
import {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormSelect,
    BBadge,
    BModal,
    BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import TeamsMixins from "../../../mixins/TeamsMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from '../../../mixins/UserMixins';
import vSelect from "vue-select";
export default {
    components: {
        BOverlay,
        BButton,
        BFormGroup,
        BFormInput,
        BForm,
        BBadge,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        required,
        min,
        BFormSelect,
        BModal,
        BFormTextarea,
        vSelect
    },
    data() {
        return {
            formData: {
                name: '',
                description: '',
                users_id: []
            },
            users: [],
            search: '',
            users_options: [],
            userselected: []
        }
    },
    mixins: [ResponseMixins, TeamsMixins, UserMixins],
    props: {
        closeSidebarEdit: {
            type: Function,
            required: true,
        },
        reloadParent: {
            type: Function,
            required: true,
        },
        teamid: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.getAddUsers();
        this.getTeamDetailandSetData()
    },
    computed: {
        userOptions() {
            // Filter user options based on user_ids
            return this.users.filter(user => this.user_ids.includes(user.value));
        }
    },
    methods: {
        AddUserToTeam(userselected) {
            this.formData.users_id.push(userselected.value)
        },
        handleSubmitClick() {
            this.UpdateTeam(this.formData,this.userselected,this.teamid).then((res) => {
                this.reloadParent();
                this.closeSidebarEdit();
                this.handleResponse(res)
            })
        },
        getAddUsers() {
            this.getUsers({include_vendor_users:true}).then((res) => {
                this.users = res.data.data.data;
                this.users.map((user) => {
                    const user_item = {
                        'text': user.firstname + user.lastname,
                        'value': user._id
                    }
                    this.users_options.push(user_item)
                })


            })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTeamDetailandSetData() {
            this.getTeamDetails(this.teamid).then((res) => {
                console.log(res.data)
                this.formData.name = res.data.data.name;
                this.formData.description = res.data.data.description;
                this.userselected = res.data.data.user_ids;
            })
        }

    }
}
</script>