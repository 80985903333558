var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-bottom d-flex"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)])]),_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"add_vendor_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick()}}},[_c('b-form-group',{attrs:{"label":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"Name","name":"Name","rules":{
                    required: true,
                    min: 3,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"Description","name":"Description","rules":{
                    required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Description","rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Add Users"}},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"label":"text","options":_vm.users_options,"autocomplete":"","multiple":"","state":"errors.length > 0 ? false:null","reduce":function (loc) { return loc.value; }},model:{value:(_vm.userselected),callback:function ($$v) {_vm.userselected=$$v},expression:"userselected"}})],1),_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('b-button',{staticClass:"mt-1 w-25",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Create")])],1)],1)],1)]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start",staticStyle:{"width":"70%"}},[_c('h2',{staticClass:"font-weight-bolder"},[_c('span',{staticClass:"align-middle"},[_vm._v("Add new Teams")])]),_c('p',{staticClass:"card-subtitle text-muted pt-75"},[_vm._v(" Fill the form and click submit to create new team. ")])])}]

export { render, staticRenderFns }